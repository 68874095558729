import { activeSystemStorage } from "@/utils/storage"

export const systemId = null

/**
 * 进入系统时，将系统的ID存储到sessionStorage中：便于公共部分获取系统ID
 */
export function initConfig() {
  activeSystemStorage.set(systemId)
}
