import { App } from "vue"
import { Router, RouteRecordRaw } from "vue-router"
import { createRouter } from "@/routes/router"
import { defRoutes, createNotFoundRoute, WHITE_LIST, LOGIN_URL } from "@/routes"
import NProgress from "@/utils/progress"
import Home from "../pages/home/index.vue"
import { useAppStoreHook } from "@/store/app"

const router = createRouter(getDefaultRoutes())

export function setupRouter(app: App) {
  app.use(router)

  setBasicPermissionGuard(router)

  return router
}

/**
 * 获取默认路由列表
 * @returns
 */
function getDefaultRoutes() {
  const routes: RouteRecordRaw[] = [...defRoutes]
  routes.push({
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "首页",
      showLink: false,
      rank: 101,
    },
  })

  routes.push(createNotFoundRoute())

  return routes
}

/**
 * 设置路由守卫
 * @param router
 */
export function setBasicPermissionGuard(router: Router) {
  const store = useAppStoreHook()

  router.beforeEach(async (to, from, next) => {
    const { token, user, getUserData, logout } = store

    // start progress bar
    NProgress.start()

    if (token) {
      if (!user) {
        try {
          await getUserData()
        } catch {
          logout()
        }
      }

      if (to.path === LOGIN_URL) {
        // if is logged in, redirect to the home page
        next({ path: "/" })
      } else {
        next()
      }
    } else {
      /* has no token*/
      if (WHITE_LIST.indexOf(to.path) !== -1) {
        // in the free login whitelist, go directly
        next()
      } else {
        // other pages that do not have permission to access are redirected to the login page.
        next(LOGIN_URL)
      }
    }

    NProgress.done()
  })

  router.afterEach(() => {
    // finish progress bar
    NProgress.done()
  })
}
